import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import AppSeo from "../components/AppSeo"

import { Button } from "../components/Button"
import Layout from "../components/Layout"

const ServiceTemplate = ( {data} ) => {

  const service = data.allServicesJson.edges;
  const heroimage = getImage(service[0].node.heroimage)
  const serviceimage1 = getImage(service[0].node.serviceimages[0])
  const serviceimage2 = getImage(service[0].node.serviceimages[1])
  const serviceimage3 = getImage(service[0].node.serviceimages[2])
  const focusimage1 = getImage(service[0].node.focusimages[0])
  const focusimage2 = getImage(service[0].node.focusimages[1])
  const focusimage3 = getImage(service[0].node.focusimages[2])



  return(
    <Layout>
      <AppSeo title={service[0].node.service}/>
      <ServiceSection>
        <ServiceHero>
          <ServiceImage>
            <GatsbyImage image={heroimage} alt="Hero image" layout="constrained"/>
          </ServiceImage>
          <ServiceTitle>{service[0].node.service}</ServiceTitle>
        </ServiceHero>
        <Textbox>
          <SubTitle>{service[0].node.title}</SubTitle>
          <TextParagraph>{service[0].node.titletext}</TextParagraph>
          <TextParagraph>{service[0].node.titletext2}</TextParagraph>
        </Textbox>
        <SubServices>
          <SubService>
            <SubServiceTitle>{service[0].node.subservice1}</SubServiceTitle>
            <SubServiceText>{service[0].node.subservice1text}</SubServiceText>
          </SubService>
          <SubServiceImage>
            <GatsbyImage image={serviceimage1} alt="Service image 1" layout="constrained"/>
          </SubServiceImage>
          <SubServiceImage>
            <GatsbyImage image={serviceimage2} alt="Service image 2" layout="constrained"/>
          </SubServiceImage>
          <SubService>
            <SubServiceTitle>{service[0].node.subservice2}</SubServiceTitle>
            <SubServiceText>{service[0].node.subservice2text}</SubServiceText>
          </SubService>
          <SubService>
            <SubServiceTitle>{service[0].node.subservice3}</SubServiceTitle>
            <SubServiceText>{service[0].node.subservice3text}</SubServiceText>
          </SubService>
          <SubServiceImage>
            <GatsbyImage image={serviceimage3} alt="Service image 3" layout="constrained"/>
          </SubServiceImage>
        </SubServices>
        <Textbox>
          <SubTitle>{service[0].node.additionalservice}</SubTitle>
          <TextParagraph>{service[0].node.additionalservicetext}</TextParagraph>
        </Textbox>
        <FocusArea>
          <FocusText>{service[0].node.focustext}</FocusText>
          <FocusImage>
            <GatsbyImage image={focusimage1} alt="Focus image 1" layout="constrained" style={{scale: '0.6'}}/>
            <GatsbyImage image={focusimage2} alt="Focus image 2" layout="constrained" style={{scale: '0.6'}}/>
            <GatsbyImage image={focusimage3} alt="Focus image 3" layout="constrained" style={{scale: '0.6'}}/>
          </FocusImage>
        </FocusArea>
        <Textbox>
          <SubTitle>{service[0].node.keyquestion}</SubTitle>
          <TextParagraph>{service[0].node.keyanswer}</TextParagraph>
        </Textbox>
        <CtaBox>
          <CtaTitle>{service[0].node.ctaquestion}</CtaTitle>
          <CtaText>{service[0].node.ctatext}</CtaText>
          <Button to="/#contact" primary="true" fweight="true">Contact me</Button>
        </CtaBox>
      </ServiceSection>
    </Layout>
  )
}

export const query = graphql`
  query serviceQuery ( $slug: String ) {
    allServicesJson (filter: {slug: {eq: $slug} }) {
      edges {
        node {
          heroimage {
            childImageSharp {
              gatsbyImageData
            }
          }
          titletext2
          titletext
          title
          subservice3text
          subservice3
          subservice2text
          subservice2
          subservice1text
          subservice1
          slug
          serviceimages {
            childImageSharp {
              gatsbyImageData
            }
          }
          service
          keyquestion
          keyanswer
          additionalservice
          additionalservicetext
          ctaquestion
          ctatext
          focusimages {
            childImageSharp {
              gatsbyImageData
            }
          }
          focustext
        }
      }
    }
  }
`

export default ServiceTemplate;

const ServiceSection = styled.div`
    color: #2A2C2B;
    margin: 0 9.375rem;
    padding: 0rem calc((100vw - 1440px) / 2) 0rem;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const ServiceHero = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`

const ServiceTitle = styled.h1`
    font-weight: 800;
    font-size: 2em;
    grid-row-start: 1;
    grid-column-start: 1;

    margin: 25% auto auto 0;
    padding: 1rem 4rem 1rem 2rem;
    background-color: rgba(255,255,255, 0.9);

    @media screen and (max-width: 1020px){
    font-size: 1.2em;
    }

    @media screen and (max-width: 768px){
    font-size: 1em;
    }
`

const ServiceImage = styled.div`
    grid-row-start: 1;
    grid-column-start: 1;
    z-index: -1;
`

const Textbox = styled.div`
    margin: 4rem 0 4rem 0;
`

const SubTitle = styled.h2`
    font-weight: 600;
    font-size: 1.6em;
    margin-bottom: 1.4rem;
`

const TextParagraph = styled.p`
    font-size: 1.2em;
    margin-bottom: 1.4rem;
    width: 70%;

    @media screen and (max-width: 1020px){
    width: 80%;
    }

    @media screen and (max-width: 768px){
      width: 95%;
    }
`

const SubServices = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 4rem;

    @media screen and (max-width: 1020px){
    grid-template-columns: 1fr;
    }

    @media screen and (max-width: 768px){

    }
`

const SubService = styled.div`
    padding: 6rem 4rem 6rem 0;
    grid-column: 1 / span 2;
    width: auto;
    :nth-child(2n){
      grid-column: 2 / span 2;
    }

    @media screen and (max-width: 1020px){
    grid-column: 1;
    width: auto;
    :nth-child(2n){
      grid-column: 1;
    }
    }

    @media screen and (max-width: 768px){
    
    }
`

const SubServiceImage = styled.div`
    width: 500px;
    align-self: center;

    @media screen and (max-width: 1020px){
    grid-column: 1;
    width: 400px;
    }

    @media screen and (max-width: 768px){
    
    }
`

const SubServiceTitle = styled.h3`
    margin-bottom: 2rem;
`

const SubServiceText = styled.p`
    font-size: 1.2em;
    line-height: 2rem;
`

const FocusArea = styled(Textbox)`
    display: grid;
    padding: 2rem 0 4rem;
    background-color: #2A2C2B;
    color: #fff;
`

const FocusText = styled(SubTitle)`
    display: flex;
    color: #939DA3;
    font-weight: 400;
    font-size: 1.2rem;
    justify-content: center;
    text-align:center;
`

const FocusImage = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 0 9rem;

    @media screen and (max-width: 1020px){
    padding: 0 4rem;
    }

    @media screen and (max-width: 768px){
    padding: 0 1rem;
    }
`

const CtaBox = styled(FocusArea)`
    padding: 4em 4em;
    margin-bottom: 4em;
`

const CtaTitle = styled.h3`
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: .4em;
`

const CtaText = styled(SubTitle)`
    font-size: 1.2em;
    font-weight: 400;
    color: #939DA3;
`